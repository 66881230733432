import pick from 'lodash/pick';
import omit from 'lodash/omit';
import {
  TAuthConverter,
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/convertor.types';
import {
  TProfileDetails,
  TTenantDataFromDb,
  TProfileDetailsUI,
  TProfilePictureUpdate,
  TNameUpdate,
  TEmailUpdate,
  TPhoneUpdate,
  TProfilePictureUpdateUI,
  TNameUpdateUI,
  TEmailUpdateUI,
  TPhoneUpdateUI,
  TApplicationProfile,
  TApplicationProfileUI,
  TEsaMetadataUpdateUI,
  TEsaMetadataUpdate,
  TSaMetadataUpdateUI,
  TSaMetadataUpdate,
  TAnimalMetadataUI,
  TAnimalMetadata,
  TTenantProperty,
  TTenantPropertyDB,
  TRoommateHasAnimalUi,
  TRoommateHasAnimal,
  TTenantCreateUI,
  TTenantCreateDB,
  ETenantActionUI,
  ETenantActionDB,
  TTenantDataUI,
  TOnboardTenantDB,
  TOwnerHasAnimalUi,
  TOwnerHasAnimal,
  ETenantResidencyStatusUI,
  ETenantResidencyStatus,
  TTenantResidencyInfoUI,
  TTenantResidencyInfo,
  TCheckTenantDuplicateUI,
  TCheckTenantDuplicateDB,
  TDuplicateTenantUI,
  TDuplicateTenantDB,
} from 'data/types/tenant.types';
import { formattedDate } from 'helpers/utils';
import {
  editingFieldConvertor,
  emailMaskConverter,
  failsConvertor,
  phoneNumberConverter,
} from './general.converters';
import { getHPDocPacketsConvertor } from './docPacket.convertors';
import { applicationSADocPacketConvertor } from './animal.convertors';

export const tenantActionConverter: TConvertor<
  ETenantActionUI,
  ETenantActionDB
> = {
  fromDb: data => {
    const dataMapper: { [key in ETenantActionDB]: ETenantActionUI } = {
      [ETenantActionDB.COMPLETE_PROFILE]: ETenantActionUI.COMPLETE_PROFILE,
      [ETenantActionDB.SCAN_QR]: ETenantActionUI.SCAN_QR,
      [ETenantActionDB.COMPLETE_RESIDENCY]: ETenantActionUI.COMPLETE_RESIDENCY,
      [ETenantActionDB.NO_ACTION]: ETenantActionUI.NO_ACTION,
      [ETenantActionDB.COMPLETE_RESIDENCY_APPLICATION]:
        ETenantActionUI.COMPLETE_RESIDENCY_APPLICATION,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: { [key in ETenantActionUI]: ETenantActionDB } = {
      [ETenantActionUI.COMPLETE_PROFILE]: ETenantActionDB.COMPLETE_PROFILE,
      [ETenantActionUI.SCAN_QR]: ETenantActionDB.SCAN_QR,
      [ETenantActionUI.COMPLETE_RESIDENCY]: ETenantActionDB.COMPLETE_RESIDENCY,
      [ETenantActionUI.NO_ACTION]: ETenantActionDB.NO_ACTION,
      [ETenantActionUI.COMPLETE_RESIDENCY_APPLICATION]:
        ETenantActionDB.COMPLETE_RESIDENCY_APPLICATION,
    };
    return dataMapper[data];
  },
};
export const tenantResidencyStatusConverter: TConvertor<
  ETenantResidencyStatusUI,
  ETenantResidencyStatus
> = {
  fromDb: data => {
    const dataMapper: {
      [key in ETenantResidencyStatus]: ETenantResidencyStatusUI;
    } = {
      [ETenantResidencyStatus.APPROVED]: ETenantResidencyStatusUI.APPROVED,
      [ETenantResidencyStatus.PENDING]: ETenantResidencyStatusUI.PENDING,
      [ETenantResidencyStatus.MOVED_IN]: ETenantResidencyStatusUI.MOVED_IN,
      [ETenantResidencyStatus.REJECTED]: ETenantResidencyStatusUI.REJECTED,
      [ETenantResidencyStatus.NOTICE]: ETenantResidencyStatusUI.NOTICE,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: {
      [key in ETenantResidencyStatusUI]: ETenantResidencyStatus;
    } = {
      [ETenantResidencyStatusUI.APPROVED]: ETenantResidencyStatus.APPROVED,
      [ETenantResidencyStatusUI.PENDING]: ETenantResidencyStatus.PENDING,
      [ETenantResidencyStatusUI.MOVED_IN]: ETenantResidencyStatus.MOVED_IN,
      [ETenantResidencyStatusUI.REJECTED]: ETenantResidencyStatus.REJECTED,
      [ETenantResidencyStatusUI.NOTICE]: ETenantResidencyStatus.NOTICE,
    };
    return dataMapper[data];
  },
};

export const getTenantProfileConvertor: TAuthConverter<
  void,
  void,
  TTenantDataUI,
  TTenantDataFromDb
> = {
  toDb: () => {
    /* do nothing */
  },
  fromDb: data => ({
    name: data.name,
    username: data.username,
    email: data.email,
    phone: data.phone,
    profilePicture: data.profilePicture,
    userId: data.userId,
    hasTraining: data.hasTraining,
    requiredAction: tenantActionConverter.fromDb(data.requiredAction),
  }),
};

export const getTenantPropertiesConvertor: TFetchConverter<
  TTenantProperty[],
  TTenantPropertyDB[]
> = {
  fromDb: properties =>
    properties.map(property => ({
      name: property.name,
      propertyId: property.propertyId,
    })),
};

export const tenantProfilePictureUpdateConverter: TCreateConverter<
  TProfilePictureUpdateUI,
  TProfilePictureUpdate
> = {
  toDb: data => ({ profilePicture: data.value }),
};

export const roommateHasAnimalUpdateConverter: TCreateConverter<
  TRoommateHasAnimalUi,
  TRoommateHasAnimal
> = {
  toDb: data => ({
    roommateAnimalStatus: data.roommateHasAnimal,
    id: data.id,
    residencyStatus: tenantResidencyStatusConverter.toDb(data.residencyStatus),
  }),
};

export const ownerHasAnimalUpdateConverter: TCreateConverter<
  TOwnerHasAnimalUi,
  TOwnerHasAnimal
> = {
  toDb: data => ({
    animalStatus: data.hasAnimal,
    id: data.id,
    residencyStatus: tenantResidencyStatusConverter.toDb(data.residencyStatus),
  }),
};

export const tenantNameUpdateConverter: TCreateConverter<
  TNameUpdateUI,
  TNameUpdate
> = {
  toDb: data => ({ name: data.value }),
};

export const tenantEmailUpdateConvertor: TCreateConverter<
  TEmailUpdateUI,
  TEmailUpdate
> = {
  toDb: data => ({ email: data.value }),
};

export const tenantPhoneUpdateConvertor: TCreateConverter<
  TPhoneUpdateUI,
  TPhoneUpdate
> = {
  toDb: data => ({
    phone: phoneNumberConverter.toDb(data.value),
  }),
};

export const esaMetadataUpdateConvertor: TCreateConverter<
  TEsaMetadataUpdateUI,
  TEsaMetadataUpdate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      hasEsaLetterForAnimal: data.hasEsa,
      description: data.description || null,
    };
  },
};

export const saMetadataUpdateConvertor: TCreateConverter<
  TSaMetadataUpdateUI,
  TSaMetadataUpdate
> = {
  toDb: data => ({
    animalId: data.animalId,
    trainedForObservableDisability: data.isObservable === 'Yes',
    description: data.description || null,
  }),
};

export const tenantResidencyInfoConverter: TFetchConverter<
  TTenantResidencyInfoUI,
  TTenantResidencyInfo
> = {
  fromDb: ({
    unitNumber,
    roommateHasAnimal,
    hasAnimal,
    id,
    status,
    leaseToDate,
    propertyId,
    moveInDate,
    leasePacketId,
    propertyManagementLogo,
    propertyManagementName,
    propertyName,
    rejectReason,
    editAllowed,
    nextLeaseToDate,
    unitId,
  }) => {
    return {
      id,
      leaseToDate: leaseToDate ? formattedDate(leaseToDate) : '-',
      moveInDate: moveInDate ? formattedDate(moveInDate) : '-',
      nextLeaseToDate: nextLeaseToDate ? formattedDate(nextLeaseToDate) : null,
      leasePacketId,
      propertyId,
      propertyManagementLogo,
      propertyManagementName,
      propertyName,
      unitNumber,
      hasAnimal,
      roommateHasAnimal,
      residencyStatus: tenantResidencyStatusConverter.fromDb(status),
      rejectReason,
      canEdit: editAllowed,
      unitId,
    };
  },
};

export const getTenantConvertor: TFetchConverter<
  TProfileDetailsUI,
  TProfileDetails
> = {
  fromDb: data => ({
    profilePicture: editingFieldConvertor.fromDb(data.profilePicture),
    name: editingFieldConvertor.fromDb(data.name),
    email: editingFieldConvertor.fromDb(data.email),
    phone: editingFieldConvertor.fromDb(data.phone),
    hpDocumentPackets: data.hpDocumentPackets?.map(packet =>
      getHPDocPacketsConvertor.fromDb(packet),
    ),
    tenantResidencyInfoCards: (data.tenantResidencyInfoCards || []).map(item =>
      tenantResidencyInfoConverter.fromDb(item),
    ),
  }),
};

export const createTenantProfileConvertor: TAuthConverter<
  TTenantCreateUI,
  TTenantCreateDB,
  void,
  void
> = {
  fromDb: () => {
    /* do nothing */
  },
  toDb: data => ({
    name: data.name,
    email: data.email || null,
    phone: phoneNumberConverter.toDb(data.phone),
    profilePicture: data.profilePicture?.url || '',
    isNotificationAgreementAccepted: data.isNotificationAgreementAccepted,
  }),
};

export const onboardTenantConvertor: TCreateConverter<
  TTenantCreateUI,
  TOnboardTenantDB
> = {
  toDb: data => ({
    name: data.name,
    email: data.email || null,
    phone: phoneNumberConverter.toDb(data.phone),
    profilePicture: data.profilePicture?.url || '',
    isNotificationAgreementAccepted: data.isNotificationAgreementAccepted,
  }),
};

export const animalMetadataConvertor: TFetchConverter<
  TAnimalMetadataUI,
  TAnimalMetadata
> = {
  fromDb: data => {
    if (!data) {
      return null;
    }

    return {
      present: data.present,
      description: data?.description || null,
    };
  },
};

export const applicationProfileConvertor: TFetchConverter<
  TApplicationProfileUI,
  TApplicationProfile
> = {
  fromDb: data => {
    const documentsFails = pick(data.fails || {}, ['hpDocumentPackets']);
    return {
      profilePicture: editingFieldConvertor.fromDb(data.profilePicture),
      name: editingFieldConvertor.fromDb(data.name),
      email: editingFieldConvertor.fromDb(data.email),
      phone: editingFieldConvertor.fromDb(data.phone),
      fails: failsConvertor.fromDb(omit(data.fails || {}, 'hpDocumentPackets')),
      documentsFails: failsConvertor.fromDb(documentsFails),
      animalMetadata: animalMetadataConvertor.fromDb(
        data.specifiedAnimalMetadata,
      ),
      tenantResidencyInfoCards: (data.tenantResidencyInfoCards || []).map(
        item => tenantResidencyInfoConverter.fromDb(item),
      ),
      packets: (data.saDocumentPackets || []).map(item =>
        applicationSADocPacketConvertor.fromDb(item),
      ),
      hpDocumentPackets: data.hpDocumentPackets?.map(packet => ({
        ...getHPDocPacketsConvertor.fromDb(packet),
        fails: failsConvertor.fromDb(packet.fails || {}),
      })),
    };
  },
};
export const propertyInfoConverter: TCreateConverter<
  TCheckTenantDuplicateUI,
  TCheckTenantDuplicateDB
> = {
  toDb: data => ({
    propertyName: data.propertyName.label,
    propertyManagementId: data.propertyManagementId,
    unitNumber: data.unit.label,
  }),
};

export const duplicateTenantConverter: TFetchConverter<
  TDuplicateTenantUI,
  TDuplicateTenantDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      username: emailMaskConverter.fromDb(data.username),
    };
  },
};
