import { lazy } from 'react';
import { TIconNames } from 'components/Base/Icon';
import { PublicRouteType, PrivateRouteType, RoutesPaths } from './Routes.types';

const Login = lazy(() => import('pages/public/Login'));
const Signup = lazy(() => import('pages/public/Signup'));
const Animal = lazy(() => import('pages/private/Animal'));
const Visitation = lazy(() => import('pages/private/Visitation'));
const Application = lazy(() => import('pages/private/Application'));
const UserProfile = lazy(() => import('pages/private/Profile'));
const PageNotFound = lazy(() => import('pages/private/PageNotFound'));
const ResetPassword = lazy(() => import('pages/public/ResetPassword'));
const NewPassword = lazy(() => import('pages/public/NewPassword'));
const Onboarding = lazy(() => import('pages/public/Onboarding'));
const Payment = lazy(() => import('pages/private/Payment'));
const SocialAuth = lazy(() => import('pages/public/SocialAuth'));
const VerifyByEmail = lazy(() => import('pages/public/VerifyByEmail'));
const Notice = lazy(() => import('pages/private/Notice'));
const Curriculum = lazy(() => import('pages/private/Curriculum'));
const PropertyManagement = lazy(
  () => import('pages/private/PropertyManagement'),
);
const KnowledgeBase = lazy(() => import('pages/private/KnowledgeBase'));

export const publicRoutes: PublicRouteType[] = [
  {
    component: Login,
    path: RoutesPaths.LOGIN,
  },
  {
    component: Signup,
    path: RoutesPaths.SIGNUP,
  },
  {
    component: ResetPassword,
    path: RoutesPaths.RESET_PASSWORD,
  },
  {
    component: NewPassword,
    path: RoutesPaths.NEW_PASSWORD,
  },
  {
    component: Onboarding,
    path: RoutesPaths.ONBOARDING,
  },
  {
    component: SocialAuth,
    path: RoutesPaths.SOCIAL_AUTH,
  },
  {
    component: VerifyByEmail,
    path: RoutesPaths.VERIFY_BY_EMAIL,
  },
];
export const privateRoutes: PrivateRouteType[] = [
  {
    title: 'Animal',
    component: Animal,
    icon: TIconNames.PET_POW,
    path: RoutesPaths.ANIMAL,
    show: true,
  },
  {
    title: 'Visitation',
    component: Visitation,
    icon: TIconNames.PET_POW,
    path: RoutesPaths.VISITATION,
    show: true,
  },
  {
    title: 'Profile',
    component: UserProfile,
    path: RoutesPaths.USER_PROFILE,
    show: false,
  },
  {
    title: 'Payment',
    component: Payment,
    path: RoutesPaths.PAYMENT,
    show: false,
  },
  {
    title: 'Application',
    component: Application,
    path: RoutesPaths.APPLICATION,
    show: false,
  },
  {
    title: 'Application',
    component: PropertyManagement,
    path: RoutesPaths.PROPERTY_MANAGEMENT,
    show: false,
  },
  {
    title: 'Curriculum',
    component: Curriculum,
    path: RoutesPaths.CURRICULUM,
    show: true,
  },
  {
    title: 'Animal complaint',
    component: Notice,
    path: RoutesPaths.COMPLAINT,
    show: true,
  },
  {
    title: '404',
    component: PageNotFound,
    path: RoutesPaths.PAGE_NOT_FOUND,
    show: false,
  },
  {
    title: 'Knowledge Base',
    component: KnowledgeBase,
    path: RoutesPaths.KNOWLEDGE_BASE,
    show: false,
  },
];
